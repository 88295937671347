import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'goods-received',
    loadChildren: () => import('./pages/goods-received/goods-received.module').then( m => m.GoodsReceivedPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'scan-cartons',
    loadChildren: () => import('./pages/scan-cartons/scan-cartons.module').then( m => m.ScanCartonsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'loadsheet',
    loadChildren: () => import('./pages/loadsheet/loadsheet.module').then( m => m.LoadsheetPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'driver-consignments',
    loadChildren: () => import('./pages/driver-consignments/driver-consignments.module').then( m => m.DriverConsignmentsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'loadsheet-palleting',
    loadChildren: () => import('./pages/loadsheet-palleting/loadsheet-palleting.module').then( m => m.LoadsheetPalletingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'loadsheet/create',
    loadChildren: () => import('./pages/loadsheet/create/loadsheet-create.module').then( m => m.LoadsheetCreatePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'manifest-scan',
    loadChildren: () => import('./pages/manifest-scan/manifest-scan.module').then( m => m.ManifestScanPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'receive-pos',
    loadChildren: () => import('./pages/receive-pos/receive-pos.module').then( m => m.ReceivePosPageModule),
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
