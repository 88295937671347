import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private alertCtrl: AlertController) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (request.url.endsWith('/staff/token')) {
      return next.handle(request);
    }
    let promise = Storage.get({ key: 'xdock-scanning-data'});
    return from(promise).pipe(
      mergeMap(user => {
        const token = JSON.parse(user.value).access_token;
        let clonedReq = this.addToken(request, token);
        return next.handle(clonedReq).pipe(
          catchError(async (error: any) => {
            // Perhaps display an error for specific status codes here already?
            let msg = error.message;

            const alert = await this.alertCtrl.create({
              header: error.name,
              message: msg,
              buttons: ['OK']
            });
            await alert.present();

            // Pass the error to the caller of the function
            throw error;
          })
        ) as any;;
      }) 
    ) as any;;
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: `application/json`,
          'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`
        }
      });
      return clone;
    }

    return request;
  }
}
