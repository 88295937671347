import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  authUser: any;

  public appPages = [];
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  async logout() {
    await this.authService.logout();
    window.location.reload();
    // this.router.navigateByUrl("/", { replaceUrl: true });
  }

  ngOnInit() {
    console.log('init');
    
    this.authService.autData.subscribe(auth =>{
      
      if (auth) {
        this.authUser = auth.user_data;  
      }

    })
    
  }
}
