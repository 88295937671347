import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

import { Amplify } from 'aws-amplify';

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: 'https://whsygvmkxzfttaqjjjiz7xum2q.appsync-api.ap-southeast-2.amazonaws.com/graphql',
      region: 'ap-southeast-2',
      defaultAuthMode: 'apiKey',
      apiKey: 'da2-qcb7of6cfjasli7zzjwwnhjdeu'
    }
  }
});



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
