import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';


const AUTH_DATA = 'xdock-scanning-data';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  autData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  auth = '';

  constructor(private http: HttpClient) {
    this.loadAuthData();
   }

  async loadAuthData() {
    const auth = await Storage.get({ key: AUTH_DATA });    
    if (auth && auth.value) {
      // console.log('set token: ', auth.value);
      this.auth = JSON.parse(auth.value);
      this.autData.next(JSON.parse(auth.value));      
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { email, password }): Observable<any> {
    return this.http.post(`${environment.apiUrl}/staff/token`, credentials).pipe(
      map((data: any) => data),
      switchMap((response: any) => {
        this.auth = response;        
        return from(Storage.set({key: AUTH_DATA, value: JSON.stringify(response)}));
      }),
      tap(_ => {
        this.autData.next(this.auth);
        this.isAuthenticated.next(true);
      })
    );
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    return Storage.remove({key: AUTH_DATA});
  }
}
